import mitt from 'mitt'
import type { Chatbox } from './chatbox'

type Events = {
  close: void
  open: void
  initial: Chatbox

  /**
   * reset is not a real event,
   * it's provided to reset the chatbox
   */
  reset: null
}

interface MessageData {
  kind?: 'initial'|'close'|'open'
  from?: 'tudongchat'
  data?: any
}

type EventMessage = MessageEvent<MessageData>

const emitter = mitt<Events>()

const validate = (data: MessageData, kind: MessageData['kind']) => {
  return data.kind === kind && data.from === 'tudongchat'
}

window.addEventListener('message', ({ data }: EventMessage) => {
  if (validate(data, 'initial')) {
    emitter.emit('initial', data.data)
  }
})

window.addEventListener('message', ({ data }: EventMessage) => {
  if (validate(data, 'close')) {
    emitter.emit('close')
  }
})

window.addEventListener('message', ({ data }: EventMessage) => {
  if (validate(data, 'open')) {
    emitter.emit('open')
  }
})

export default emitter
