import TuDongChat from './TuDongChat'
import { inject } from './analytics/plausible'

if (import.meta.env.DEV) {
  const tudongchat = new TuDongChat('a0PtH6doG8iI6a7c25h9o', {
    persistent: true
  })

  tudongchat.initial()

  globalThis.tudongchat = tudongchat
}

if (window) {
  window.TuDongChat = TuDongChat
}

declare global {
  interface Window {
    TuDongChat: typeof TuDongChat
  }
}

export default TuDongChat
