import { nanoid } from 'nanoid'

let development = import.meta.env.DEV

export let app_url = !development
  ? 'https://app.tudongchat.com'
  : 'http://localhost:5173'

export let api_url = !development
  ? 'https://shark.tudongchat.com'
  : 'http://localhost:3000'


export const setDevelopment = (dev: boolean) => {
  development = dev

  app_url = !development
    ? 'https://app.tudongchat.com'
    : 'http://localhost:5173'
  api_url = !development
    ? 'https://shark.tudongchat.com'
    : 'http://localhost:3000'
}

export const createRequest = (id: string) => {
  const footprint = nanoid()

  const current = encodeURIComponent(window.location.href)
  const timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const footprint_url = `${api_url}/guest/visit?footprint=${footprint}&id=${id}&url=${current}&timezone=${timezone}`
  const iframe_url = `${app_url}/chat/${id}?footprint=${footprint}&url=${current}`

  return { footprint_url, iframe_url, footprint }
}
