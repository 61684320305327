<div bind:this={wrapper} class="tudongchat-com" class:t-hidden={!isInit} class:ltr={position === 'left'}>
  <div bind:this={boxchat} class="tudongchat-box" class:t-hidden={hidden} class:tudongchat-animate-open={animate}>
    <iframe src={iframe_url} title="Main boxchat" frameborder="0"></iframe>
  </div>

  {#if hidden}
  <div transition:fly bind:this={icon} class="tudongchat-icon" on:click={toggle} style="background-color: {color};">
    <img class="tudongchat-icon-logo" src={app_url + '/images/chat-4-xxl.png'} alt="" />
  </div>
  {/if}

  <iframe title="Tracker" class="tracker" src={footprint_url} frameborder="0"></iframe>
</div>

<script lang="ts">
import { onDestroy } from 'svelte'
import { fly } from 'svelte/transition'
import { app_url, createRequest } from '../../store/target'
import { chatbox, state } from '~/store/chatbox'

export let id: string

const { footprint_url, iframe_url } = createRequest(id)
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

let hidden = true
let isInit = false
let animate = false
let color = '#0598ff'
let position: 'left' | 'right' = 'right'

let wrapper: HTMLDivElement
let icon: HTMLDivElement
let boxchat: HTMLDivElement

const body = window.document.body
const indexer = {
  current: 20,

  get force () {
    return 2147483644
  },

  get default () {
    return 20
  },

  set default (value: number) {
    this.current = value
  },

  get isReady () {
    return !!wrapper && !!icon && !!boxchat
  },

  update(index?: number) {
    index ??= this.current

    if (this.isReady) {
      wrapper.style.zIndex = index.toString()
      icon.style.zIndex = (index - 1).toString()
      boxchat.style.zIndex = index.toString()
    }

    this.current = index
  }
}

const unsubscribe = chatbox.subscribe(box => {
  isInit = !!box

  if (!box) return

  color = box.color || ''
  indexer.default = box.z_index ?? indexer.default
  position = box.position || 'right'

  if (indexer.isReady) {
    indexer.update()
    box.color && (icon.style.backgroundColor = box.color)
  }
})

const open = () => {
  hidden = false
  delay(50).then(() => animate = true)

  if (body.clientWidth <= 460) {
    body.style.overflow = 'hidden'
  }

  indexer.update(indexer.force)
}

const close = () => {
  animate = false
  delay(150).then(() => hidden = true)

  if (body.clientWidth <= 460) {
    body.style.overflow = ''
  }

  indexer.update(indexer.default)
}

state.subscribe(state => {
  state === 'open' ? open() : close()
})

const toggle = () => state.update(state => {
  return state === 'open' ? 'close' : 'open'
})

onDestroy(unsubscribe)
</script>

<style scoped>
.tracker {
  display: none;
}

.tudongchat-com {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  z-index: 20;
}

.tudongchat-com.ltr {
  bottom: 30px;
  left: 30px;
  flex-direction: column-reverse;
}

.tudongchat-com.ltr > .tudongchat-icon {
  right: auto;
  left: 0;
}

.tudongchat-icon {
  position: absolute;
  bottom: 0;
  right: 0;

  cursor: pointer;
  background-color: #0598ff;
  border-radius: 100%;
  margin-top: auto;
  transition: all .15s ease-in-out;
  display: grid;
  padding: 15px;
  place-content: center;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.tudongchat-box {
  width: 420px;
  height: 580px;
  border-radius: 10px;
  margin-right: .5rem;

  opacity: 0;
  transition: all .15s ease-in-out;
  transform: scale(.9);
}

.tudongchat-animate-open {
  transform: scale(1);
  opacity: 1;
}

.tudongchat-box > iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.t-hidden {
  display: none;
}

@media screen and (max-width: 500px) {
  .tudongchat-box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0%;
    position: fixed;
  }

  .tudongchat-box > iframe {
    border-radius: 0;
  }

  .tudongchat-com {
    border-radius: 0;
  }
}

.tudongchat-icon-logo {
  transition: all .15s ease-in-out;
  width: 25px;
  height: 25px;
  max-width: none;
  max-height: none;
}

.tudongchat-icon:hover > .tudongchat-icon-logo {
  transform: scale(1.1);
}

.tudongchat-icon:active > .tudongchat-icon-logo {
  transform: scale(0.8);
}
</style>
