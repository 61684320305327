import { readable, writable } from 'svelte/store'
import events from './events'

export interface Chatbox {
  title: string
  subtitle: string
  color: string
  avatar: string
  z_index: number
  position: 'left'|'right'
}

export const chatbox = readable<Chatbox>(null, (set) => {
  events.on('initial', set)
  events.on('reset', () => set(null))
})

export const state = writable<'open'|'close'>('close', (set) => {
  events.on('open', () => set('open'))
  events.on('close', () => set('close'))
  events.on('reset', () => set('close'))
})

export const reset = () => {
  events.emit('reset')
}

export default { chatbox, state, reset }
