import type { ComponentType } from 'svelte'
import Chatbox from './Chatbox.svelte'
import { state } from './store/chatbox'
import emitter from './store/events'
import { inject } from './analytics/plausible'

const createRoot = (id: string) => {
  const root = document.createElement('div')
  root.id = id
  document.body.appendChild(root)
  return root
}

export interface BoxOptions {
  dev: boolean
  persistent: boolean
}

class TuDongChat {
  private el: HTMLElement
  private app: InstanceType<ComponentType<any>>

  constructor(
    private readonly id: string,
    private readonly options?: Partial<BoxOptions>
  ) {
    this.el = createRoot(this.id)
    inject(id)
  }

  initial() {
    this.app = new Chatbox({
      target: this.el,
      props: {
        id: this.id,
        ...this.options as any
      }
    })
  }

  reload() {
    if (this.app) {
      this.app.$destroy()
      this.initial()
    }
  }

  popup(show: boolean = true) {
    state.set(show ? 'open' : 'close')
  }

  onInit(fn: (data: any) => void) {
    emitter.on('initial', fn)
  }

  onClose(fn: () => void) {
    emitter.on('close', fn)
  }

  onOpen(fn: () => void) {
    emitter.on('open', fn)
  }

  off(fn: any) {
    emitter.off(fn)
  }
}

export default TuDongChat
