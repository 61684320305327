import sha1 from 'crypto-js/sha1'

const SCRIPT_URL = 'https://collect.tudongchat.com/js/script.js'


export const inject = (domain?: string) => {
  if (document.querySelector(`script[src="${SCRIPT_URL}"]`)) {
    return
  }

  if (domain) {
    domain = `${sha1(domain)}.tdc`
  }

  const script = document.createElement('script')

  script.defer = true
  script.setAttribute('data-domain', domain ?? window.location.host)
  script.setAttribute('src', SCRIPT_URL)

  document.head.appendChild(script)
}