
<Main id={id} />

<script lang="ts">
import Tudongchat from './chat/tudongchat/Tudongchat.svelte'
import { setDevelopment } from './store/target'
import { state } from './store/chatbox'

export let id: string
export let dev: boolean = undefined
export let persistent: boolean = true

let Main = Tudongchat

if (dev !== undefined) {
  setDevelopment(dev)
}

if (persistent) {
  const status = localStorage.getItem('tudongchat:state') as any
  // status && state.set(status)

  state.subscribe(state => {
    localStorage.setItem('tudongchat:state', state)
  })
}
</script>
